import React, {useEffect, useState} from 'react'
import { isMobile } from '../../services/utils';
import "./VideoIframe.scss";

type VideoIframeProps = {
    embed: string;
}

export default function VideoIframe({embed}: VideoIframeProps) {
    const [source, setSource] = useState('');
    const [device, setDevice] = useState('desktop');

    useEffect(() => {
        setSource(embed);

        isMobile() ? setDevice('mobile') : setDevice('desktop');
    }, [embed]);
    // "400px" height="250px"
    return (
        <div className="modal-iframe-wrapper animate__animated animate__flipInY ">
            {source && <iframe title={embed} id="modal-iframe" width={device === 'mobile' ? '100%' : '600px'} height={device === 'mobile' ? '250px' : '400px'} src={source} frameBorder="0"
                               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                               allowFullScreen>&nbsp;</iframe>}
        </div>
    )
}
