import React from 'react';
import { useEffect, useState } from 'react';
import { getContent } from './services/content';
import './App.scss';
import Header from './components/header/Header';
import ListVideo from './components/list-video/ListVideo';
import SingleVideo from './components/single-video/SingleVideo';
import { BrowserRouter, Route, Routes  } from 'react-router-dom';
import Info from './components/info/Info';
import Loader from './components/loader/Loader';
import Contact from './components/contact/Contact';
import { IData } from './interfaces/IData';
import { IVideoList } from './interfaces/IVideoList';

function App() {
  // Data asset
  const [content, setContent] = useState<IData>();
  // Content backup
  const [contentBackup, setContentBackup] = useState<IData>();
  // Loader visibility
  const [loaderShow, setLoaderShow] = useState<boolean>(true);

  useEffect(() => {
    getContent().then(items => {
      setContent(items);
      setLoaderShow(false);
      setContentBackup(items);
    });
  }, []);

  const search = (data: string) => {
    if (data === '') {
      setContent(contentBackup);
    } else {
      let arr: any = [];
      arr['video-list'] = [];
      if(contentBackup) {
        arr['video-list'] = contentBackup['video-list'].filter((item: IVideoList) => {
          return item['title'].toLowerCase().includes(data.toLowerCase());
        });
        setContent(arr);
      }
    }
  };

  return (
    <BrowserRouter>
        <div className="container">
          {/*LOADER*/}
          {!!loaderShow && <Loader />}
          {/*END LOADER*/}

          {/* TOP FIXED BLOCK */}
          <Header search={search} content={contentBackup} />
          {/* END TOP FIXED BLOCK */}

          <Routes>
              <Route path="/" element={<ListVideo content={content} />} />
              <Route path="/info" element={<Info />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/v/:id" element={<SingleVideo />} />
          </Routes>
        </div>
    </BrowserRouter>
  );
}

export default App;
