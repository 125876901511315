import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import './Header.scss';
import { Link } from 'react-router-dom';
import Navigation from '../navigation/Navigation';
import Search from '../search/Search';
import { IData } from '../../interfaces/IData';

type HeaderProps = {
  content: IData | undefined;
  search: (data: string) => void;
}

// const Header = (props: HeaderProps) => {
const Header = ({content, search}: HeaderProps) => {
  const usePathname = () => {
    const location = useLocation();
    return location.pathname;
  };

  const IsShowSearchBar = () => {
    const pathName = usePathname();
    return pathName === '/';
  };

  const updateInput = async (input: string) => {
    search(input);
  };

  if(!content) {
    return null;
  }

  return ( content && 
    <div className="header animate__animated animate__backInDown fixed">
      <Navigation search={search} content={content} />
      <div
        className="main-bg"
        style={{ backgroundImage: 'url("' + content['background'] + '")' }}
      />
      <div className="mobile-search">
        {IsShowSearchBar() && <Search setKeyword={updateInput} />}
      </div>
    </div>
  );
};

export default Header;
