import React from 'react';
import { Link } from 'react-router-dom';
import './Navigation.scss';
import TopMenu from '../top-menu/TopMenu';
import { useLocation } from 'react-router-dom';
import Search from '../search/Search';
import { IData } from '../../interfaces/IData';
import Sidebar from '../sidebar/Sidebar';
import { isMobile } from '../../services/utils';

type NavigationProps = {
  content: IData | undefined;
  search: (data: string) => void;
};

function Navigation({ search, content }: NavigationProps) {
  const usePathname = () => {
    const location = useLocation();
    return location.pathname;
  };

  const IsShowSearchBar = () => {
    const pathName = usePathname();
    return pathName === '/';
  };

  const updateInput = async (input: string) => {
    search(input);
  };

  if (!content) {
    return null;
  }

  return (
    <div className="navigation">
      <Sidebar />

      <div className="channel-description">
        <Link
          to={{
            pathname: `/`,
          }}
        >
          <div
            className="logo"
            style={{ backgroundImage: 'url("' + content['logo'] + '")' }}
          />
        </Link>
        <div className="header">
          <div className="title">{content['channel_title']}</div>
          <div className="subtitle">{content['subscribers']}</div>
        </div>
      </div>

      <div className="desktop-search">
        {IsShowSearchBar() && <Search setKeyword={updateInput} />}
      </div>

      {!isMobile() && (
        <div>
          <TopMenu></TopMenu>
        </div>
      )}
    </div>
  );
}

export default Navigation;
