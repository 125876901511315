import React, { useState } from 'react'
import './Search.scss';
import { ISearchProps } from '../../interfaces/ISearchProps';

export default function Search({setKeyword}: ISearchProps) {

    const [searchInput, setSearchInput] = useState<string>('');

    const updateInput = (text: string) => {
        setSearchInput(text);
        setKeyword(text);
    }

    return (
        <form className="search-form">
            <input 
                className="search-input"
                key="random1"
                value={searchInput}
                placeholder={"Search"}
                onChange={(e) => updateInput(e.target.value)}
            />
        </form>
    )
}