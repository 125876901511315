import React, { useEffect, useState } from 'react';
import "./Themes.scss";

type Props = {};

const Themes = (props: Props) => {
  const defaultTheme = 'dark';
  const colorTheme = 'color';
  const [theme, setTheme] = useState(defaultTheme);

  useEffect(() => {
    const localStorageTheme = localStorage.getItem('theme');
    if (localStorageTheme) {
      setTheme(localStorageTheme);
      document.body.className = localStorageTheme;
    } else {
      setTheme(defaultTheme);
      document.body.className = defaultTheme;
    }
  }, []);

  const changeTheme = (name: string) => {
    setTheme(name);
    document.body.className = name;
    localStorage.setItem('theme', name);
  };
  
  return (
    <div>
      {theme === 'color' ? (
        <>
          <div onClick={() => changeTheme(defaultTheme)} className="dark-theme-icon"></div>
        </>
      ) : (
        <>
          <div onClick={() => changeTheme(colorTheme)} className="color-theme-icon"></div>
        </>
      )}
    </div>
  );
};

export default Themes;
